import React from "react";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Clear";

import ProgressBar from "../assets/ProgressBar";
import ProgressIcon from "@material-ui/icons/Favorite";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "transparent",
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    paddingLeft: theme.spacing(1),
  },
  endAdornmentWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: "rgba(230,137,147, 1)",
  },
  endAdornment: {
    color: "inherit",
    marginRight: theme.spacing(0.5),
  },
}));

const TaskTopBar = (props) => {
  const { onCancel, progress, taskIndex } = props;
  const classes = useStyles();
  return (
    <AppBar color="default" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <IconButton onClick={onCancel}>
          <CancelIcon size="large" />
        </IconButton>
        <ProgressBar progress={progress} />
        <div className={classes.endAdornmentWrapper}>
          <ProgressIcon className={classes.endAdornment} />
          <Typography variant="caption" color="inherit">
            {taskIndex}
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TaskTopBar;
