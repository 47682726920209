import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "relative",
    paddingLeft: "18px",
    paddingRight: "8px",
    overflow: "hidden",
  },
});

const ProgressBar = (props) => {
  const classes = useStyles();
  const { progress } = props;
  return (
    <div className={classes.root}>
      <StyledProgressBar variant="determinate" value={progress} />
    </div>
  );
};

export default ProgressBar;

export const StyledProgressBar = withStyles((theme) => ({
  root: {
    height: 16,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor: "#6c0071",
  },
  bar: {
    borderRadius: 8,
    backgroundColor: "#ffc63d",
  },
}))(LinearProgress);
