import React from "react";
import { Paper, Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import backgroundImage from "./mountains.svg";
import backgroundImageTop from "./hcn-cloud-challenge-new.svg";
import backgroundImageBottom from "./footsteps.svg";
import backgroundImageBottomSmall from "./footsteps-small.svg";

const useStyles = makeStyles((theme) => {
  console.log(theme);
  return {
    card: {
      backgroundColor: "rgba(80,80,173,1)",
      allignItems: "center",
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "center",
      backgroundPositionX: "center",
      backgroundPosition: "absolute",
      width: "100%",
      height: "282px",
      display: "flex",
      justifyContent: "center",
    },
    outerWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    imageTop: {
      backgroundImage: `url(${backgroundImageTop})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "center",
      backgroundPositionX: "center",
      height: "80px",
      width: "115.42px",
    },
    cardAction: {
      backgroundColor: "rgba(214,61,77,1)",
      color: "rgba(255,255,255,1)",
      marginTop: "20px",
    },
    imageBottom: {
      backgroundImage: `url(${backgroundImageBottomSmall})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "center",
      backgroundPositionX: "center",
      height: "75px",
      width: "552px",
      marginTop: "20px",
      [theme.breakpoints.up("sm")]: {
        backgroundImage: `url(${backgroundImageBottom})`,
        height: "75px",
        width: "552px",
        marginTop: "20px",
      },
    },
  };
});

const NotificationCard1 = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.card}>
      <Box className={classes.outerWrapper} px={{ sm: 10, md: 20 }}>
        <div className={classes.imageTop} />
        <Button className={classes.cardAction}>challenge label</Button>
        <div className={classes.imageBottom} />
      </Box>
    </Paper>
  );
};

export default NotificationCard1;
