import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { Paper, Box, Button, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  innerWrapperContent: {
    margin: "24px",
  },

  elementMarginS: {
    marginTop: theme.spacing(2),
  },

  elementMarginXS: {
    marginTop: theme.spacing(0.5),
  },

  avatar: {
    // width: theme.spacing(5),
    // height: theme.spacing(5),
    // borderRadius: "12px",
    marginRight: theme.spacing(2),
  },

  cardAction: {
    backgroundColor: "rgba(214,61,77,1)",
    color: "rgba(255,255,255,1)",
    /*     margin: "24px", */
  },
  gutter: {
    marginRight: theme.spacing(0.5),
    fontWeight: 700,
  },
  // TODO: move into theme  },
  bold: {
    fontWeight: 700,
  },
  darkTextPrimary: {
    color: "rgba(0,0,0, 0.87)",
  },
  darkTextPrimaryBold: {
    color: "rgba(0,0,0, 0.87)",
    fontWeight: 700,
  },
  darkTextSecondary: {
    color: "rgba(0,0,0, 0.80)",
  },
}));

const StageOne = (props) => {
  const { onAction } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.innerWrapperContent} container direction="column">
      <div>
        <Typography variant="body2" className={classes.darkTextPrimaryBold}>
          Happy to see you working on skills there are in demand{" "}
          <span role="img" aria-label="high-five">
            🤘🚀
          </span>
        </Typography>
        <Box className={classes.elementMarginXS}>
          <Typography variant="caption" className={classes.darkTextPrimary}>
            This reachout helped +200 people
          </Typography>
        </Box>
      </div>
      <div className={classes.elementMarginS}>
        <Typography
          className={classes.darkTextPrimaryBold}
          variant="h4"
          display="inline"
        >
          8
        </Typography>
        <Typography
          color="textPrimary"
          className={classes.darkTextSecondary}
          variant="caption"
          display="inline"
        >
          ABC
        </Typography>
      </div>
      <div className={classes.elementMarginS}>
        <Button
          onClick={() => onAction("stageTwo")}
          className={classes.cardAction}
        >
          claim
        </Button>
      </div>
    </Grid>
  );
};

export default StageOne;
