import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, List, ListItem, Typography } from "@material-ui/core";
import backgroundImage from "./challenge-background-done-flowers.svg";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "100%",
    backgroundColor: "rgba(38,38,101, 1)",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      backgroundColor: "rgba(38,38,101, 1)",
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "bottom",
      backgroundSize: "100%",
    },
  },
  motivatorHeader: {
    fontWeight: "bold",
    color: "rgba(146,222,145, 1)",
    marginBottom: "48px",
  },
  whyElement: {
    fontWeight: "500",
    color: "rgba(255,255,255, 0.60)",
    marginBottom: "56px",
  },
  buttonContained: {
    backgroundColor: "rgba(146,222,145, 1)",
    color: "primary",
    marginBottom: "18px"
    // TODO: not production ready, press color, text color not finalized
  },
  buttonOutlined: {
    borderColor: "rgba(146,222,145, 0.50)",
    color: "rgba(146,222,145,s 0.50)",
  },
}));

const Done = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root} disableGutters maxWidth={false}>
      <Typography variant="h4" className={classes.motivatorHeader}>
        Great job :-)
      </Typography>
      <Typography
        className={classes.whyElement}
        variant="subtitle1"
      >
        Want to do one more challenge?
      </Typography>
      <Button variant="contained" className={classes.buttonContained}>
        Continue
      </Button>
      <Button variant="outlined" className={classes.buttonOutlined}>
        Skip
      </Button>
    </Container>
  );
};

export default Done;
