import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, List, ListItem, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "100%",
    backgroundColor: "#b641c2",
    color: "yellow",
  }
}));

const Done = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root} disableGutters maxWidth={false} >
      <Typography variant="h1" color="inherit">"You did it!!!!"</Typography>
    </Container>
  );
};

export default Done;
