import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Box } from "@material-ui/core";
import { MinimalTopBar } from "components/MinimalTopBar";
import NotificationCard3_5 from "components/NotificationCard3_5";

export default function NotificationCard3_5View() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Container maxWidth="sm">
      <MinimalTopBar onGoBack={handleGoBack} />
      <Box mt={3}>
        <NotificationCard3_5 />
      </Box>
    </Container>
  );
}