import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import { ButtonBase } from "@material-ui/core";
import {
  motion,
  AnimatePresence,
  useCycle,
  AnimateSharedLayout,
} from "framer-motion";
import { Frame } from "framer";
import zIndex from "@material-ui/core/styles/zIndex";

import Area3MiniIsland2 from "components/Area3MiniIsland2";

import Modal from "./Modal";

// By default all transforms are 3d.
// You should only animate transforms and opacity (can affect performance if using x and y, use translate if applicable)
// Translate shortcuts> x, y, z
// Translate: translateX, translateY, translateZ
// Scale: scale, scaleX, scaleY
// Rotate: rotate, rotateX, rotateY, rotateZ
// Skew: skew, skewX, skewY

const useStylesAnimationTest = makeStyles((theme) => {
  console.log(theme);
  return {
    boxRedParrent: {
      backgroundColor: "red",
      height: "200px",
      width: "200px",
    },
    boxYellowParrent: {
      backgroundColor: "yellow",
      height: "100px",
      width: "100px",
    },
    buttonStyle: {
      backgroundColor: "blue",
      height: "48px",
      width: "48px",
    },
  };
});


const AnimationTest = () => {
  const classes = useStylesAnimationTest();
  const [value, setValue] = useState(0);
  const [isToggled, setToggle] = useState(false);
  return (
    <Grid container>
      <ButtonBase
        onClick={() =>
          setToggle((prevValue) => {
            return prevValue ? 0 : 1;
          })
        }
        className={classes.buttonStyle}
      >
        Click to move
      </ButtonBase>

      <AnimatePresence>
        {isToggled && (
          <motion.div
            initial={{ scale: 1, x: "0%", y: "0%" }}
            animate={{ scale: 2, x: "100%", y: "100%" }}
            exit={{ scale: 1, x: "0%", y: "0%" }}
            transition={{
              ease: "easeOut",
              duration: 1,
              stiffness: 260,
              damping: 20,
            }}
          >
            <Area3MiniIsland2/>
          </motion.div>
        )}
      </AnimatePresence>
      <Modal isToggled={isToggled} setToggle={setToggle}>
        <div className={classes.boxYellowParrent}> </div>
      </Modal>
    </Grid>
  );
};

export default AnimationTest;
