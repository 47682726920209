import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Avatar } from "@material-ui/core";
import backgroundImage from "./Michael.png";

const useStylesMap = makeStyles((theme) => ({
  avatarStyling: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: "4px",
    /*     borderColor: "rgba(255,218,61, 1)", // yellow */
    borderColor: "rgba(234,234,247, 1)", // white
  },
}));

const AvatarMap = (props) => {
  const classes = useStylesMap();
  return (
    <Avatar src={backgroundImage} className={classes.avatarStyling}></Avatar>
  );
};

export default AvatarMap;
