import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, List, ListItem, Typography } from "@material-ui/core";
import backgroundImage from "./challenge-background-init.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "100%",
    backgroundColor: "rgba(84,70,152, 1)",
    fontWeight: "bold",
    color: "rgba(255,218,61, 1)",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      backgroundColor: "rgba(84,70,152, 1)",
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "bottom",
      backgroundSize: "100%",
    },
  },
}));

const Init = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth={false} >
      <Typography variant="h3" color="inherit">
        Get ready
      </Typography>
    </Container>
  );
};

export default Init;
