import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Container, Dialog, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

// https://joshwcomeau.com/react/announcing-use-sound-react-hook/
import useSound from "use-sound";

// assets
import TaskTopBar from "../assets/TaskTopBar";
import Question from "../assets/Question";
import Options from "../assets/Options";
import CancelDialog from "../assets/CancelDialog";
import TaskBottomBar from "../assets/TaskBottomBar";
import backgroundImage from "./challenge-background-task.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    height: "100%",
    backgroundColor: "#8641c2",
    color: "white",
    backgroundColor: "rgba(80,80,173, 1)",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      backgroundColor: "rgba(80,80,173, 1)",
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "bottom",
      backgroundSize: "100%",
    },
  },
  topCompensator: {
    ...theme.mixins.toolbar,
  },
  taskContainer: {
    height: "310px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    /*     justifyContent: "space-between", */
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  cancelPaper: {
    // backgroundColor: "white",
  },
  outerWrapper: {
    height: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
}));

const Task = (props) => {
  const classes = useStyles();

  /**
   * START REQUIRED TASK LOGIC
   */
  const { tasks, options, onChange, onDone, onCancel, settings } = props;
  const [taskIndex, setTask] = React.useState(0);
  const [done, setDone] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [openCancel, setOpenCancel] = React.useState(false);
  /*   const [muted, setMute] = React.useState(false); */
  const [info, setInfo] = React.useState(false);

  /*   const [
    playTaskDone,
  ] = useSound(
    "https://raw.githubusercontent.com/joshwcomeau/use-sound/master/stories/sounds/glug-b.mp3",
    { volume: 0.25, soundEnabled: !muted }
  ); */

  const task = tasks[taskIndex];

  const handleAnswer = (option) => {
    const answer = {
      option,
      task,
    };

    const progress = taskIndex;

    /*     onChange(answer, progress);
    playTaskDone(); */

    if (tasks.length - 1 > taskIndex) {
      setTask(taskIndex + 1);
      return;
    }
    setDone(true);
  };

  const throttleAnswer = React.useCallback(
    _.debounce((option) => handleAnswer(option), settings.task?.trottle || 200)
  );

  React.useEffect(() => {
    setProgress(Math.round((taskIndex / tasks.length) * 100));
  }, [taskIndex, tasks.length]);

  React.useEffect(() => {
    if (done) {
      setProgress(100);
      onDone(true);
    }
    return;
  }, [done]);
  /**
   * END REQUIRED TASK LOGIC
   */

  const handleOnCancel = () => {
    // cancel without dialog if no questions answered
    if (progress === 0) return onCancel();
    // else use cancel dialog
    setOpenCancel(true);
  };

  const dynamicProgress = () => {
    const offset = tasks.length / 3;
    const progression = offset / (tasks.length / (tasks.length - taskIndex));
    return progress + progression;
  };

  return (
    <Container className={classes.root} disableGutters maxWidth={false}>
      <div className={classes.topCompensator} />
      <TaskTopBar
        onCancel={handleOnCancel}
        taskIndex={taskIndex}
        progress={dynamicProgress()}
        cancelDisable={done}
      />
      <Grid container className={classes.outerWrapper}>
        <div className={classes.taskContainer}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Question question={task.text} />
          </Box>
          <Box>
            <Options
              options={options}
              task={task}
              done={done}
              onClick={throttleAnswer}
              info={info}
            />
          </Box>
        </div>
      </Grid>
      <Dialog
        open={openCancel}
        maxWidth={false}
        onClose={() => setOpenCancel(false)}
        PaperProps={{
          className: classes.cancelPaper,
        }}
      >
        <CancelDialog
          onCancel={onCancel}
          onContinue={() => setOpenCancel(false)}
        />
      </Dialog>
    </Container>
  );
};

export default Task;
