import React, { useState, useEffect, useRef } from "react";
import lottie from "lottie-web";
import challengeIconLottie from "./challenge-icon-lottie-11.json";
import ButtonBase from '@material-ui/core/ButtonBase'

export default function App() {
  const lottieAnimation = useRef();
  const [prev, setPrev] = useState(80);
  const [next, setNext] = useState(0);
  const [tasks, setTasks] = useState([true, true, true, true, true])

  const begin = 1
  const countStart = 1
  const countFinish = 100
  const end = 150
  
  let anim
  useEffect(() => {
    anim = lottie.loadAnimation({
      container: lottieAnimation.current,
      animationData: challengeIconLottie,
      renderer: "svg",
      loop: false,
      autoplay: true,
    });
    initialize();
  },[]);

  const start = React.useCallback(() => {
    anim.playSegments([[0, 1]], true)
  })

  const move = React.useCallback((prev, next) => {
    anim.playSegments([[prev, next]], true)
  },[])

  const finish = React.useCallback((prev) => {
    anim.playSegments([[80, 150]], true)
  })

  const initialize = React.useCallback(() => {
    const step = 100/tasks.length
    const finished = tasks.filter(t => t === true).length
    const value = finished*step
    console.log(finished)
    console.log(tasks.length)
    setPrev(value)
    if (finished === 0) {
      return move(0, 1)
    }
    if (finished === tasks.length) {
      console.log("HALLO")
      return finish(prev)
    }
    move(0, value)
  }, [])

  const handleNext = React.useCallback(() => {
    const lenght = tasks.length
    const step = 100/lenght
    const finished = tasks.filter(t => t === true).length
    const value = finished*step
    if (finished === lenght -1) {
      finish(prev)
    }
    move(prev, next)
  }, [prev, next])

  return (
    <ButtonBase>
      <div ref={lottieAnimation} />
    </ButtonBase>
  );
}
