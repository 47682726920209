import React from "react";
import { makeStyles } from "@material-ui/styles";
import UncontrolledLottie from "./UncontrolledLottie";
import Grid from "@material-ui/core/Grid";

const useStylesStageFour = makeStyles((theme) => ({
/*   avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: "12px",
    marginRight: theme.spacing(2),
  }, */
}));



const StageFour = (props) => {
  const classes = useStylesStageFour();
  return (
    <Grid container direction="column" className="lotties">
      <UncontrolledLottie />
    </Grid>
  );
};

export default StageFour;
