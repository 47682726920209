import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import AvatarInterfacePanel from "components/AvatarInterfacePanel";

export default function AvatarInterfacePanelView() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <AvatarInterfacePanel />
    </Box>
  );
}
