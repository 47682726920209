import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Button, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: "20px",
  },
  subTitle: {},
}));

const CancelDialog = (props) => {
  const { onCancel, onContinue } = props;
  const classes = useStyles();
  return (
    <Box p={3} display="flex" flexDirection="column" alignItems="center">
      <Box mt={2}>
        <Typography className={classes.title} align="center">
          Are you sure you want to end this challenge?
        </Typography>
      </Box>
      <Box my={2}>
        <Typography color="textSecondary" className={classes.subTitle} align="center">
          All your progress will be lost!
        </Typography>
      </Box>
      <Box my={2} width="100%">
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={onContinue}
          fullWidth
        >
          Go Back
        </Button>
      </Box>
      <Box my={1} width="100%">
        <Button color="secondary" onClick={onCancel} fullWidth>
          End challenge
        </Button>
      </Box>
    </Box>
  );
};

export default CancelDialog;
