import React from "react";
import { makeStyles } from "@material-ui/styles";

import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "32px",
    fontWeight: 900,
  },
}));

const Question = (props) => {
  const classes = useStyles();
  const { question } = props;
  return <Typography align="center" className={classes.text}>{question}</Typography>;
};

export default Question;
