import React from "react";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import { MinimalTopBar } from "components/MinimalTopBar";
import WorldMap from "components/Zone";

import { Skin5, Skin3, Skin6} from "components/Map/skins";

/**
 * SKIN & TEST DATA
 */
/* import * as skin from "components/MapCloudIslandSkin"; */

export default function MapCloudIslandView() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Container maxWidth={false} disableGutters>
      <MinimalTopBar onGoBack={handleGoBack} static transparent />
      <WorldMap skins={{ Skin5, Skin3, Skin6}} challenges={challenges} settings={settings} />
    </Container>
  );
}

// Can only take challenges array of length [3,5,6],

export const settings = {
  baseURI: "",
};

export const challenges = [
  {
    id: "1",
    done: false,
    action: {
      type: "challenge",
      skin: "Cloudisland",
      id: "big5_1",
    },
  },
  {
    id: "2",
    done: false,
    action: {
      type: "challenge",
      skin: "Cloudisland",
      id: "big5_2",
    },
  },
  {
    id: "3",
    done: true,
    action: {
      type: "challenge",
      skin: "Cloudisland",
      id: "big5_3",
    },
  },
  {
    id: "4",
    done: false,
    action: {
      type: "challenge",
      skin: "Cloudisland",
      id: "big5_4",
    },
  },
  {
    id: "5",
    done: true,
    action: {
      type: "challenge",
      skin: "Cloudisland",
      id: "big5_5",
    },
  },
  // {
  //   id: "6",
  //   done: true,
  //   action: {
  //     type: "challenge",
  //     skin: "Cloudisland",
  //     id: "big5_6",
  //   },
  // },
];
