import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import mapImage from "./cloud-island-bg-noclouds.svg";
import AvatarInterfacePanel from "components/AvatarInterfacePanel";

const useStylesZone = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: 0,
    paddingTop: "63%",
    backgroundImage: `url(${mapImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
  },
  challengeBtn: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dude: {
    borderRadius: "50%",
    position: "absolute",
    borderStyle: "solid",
    borderWidth: "4px",
/*     borderColor: "rgba(255,218,61, 1)", // yellow */
    borderColor: "rgba(234,234,247, 1)", // white
  },
}));

const Zone = ({
  width = 590,
  iconBaseline = 52,
  avatarBaseline = 76,
  mapBaseline = 590,
  challengeButton = [true, true, true, true, true],
  avatarComponent: AvatarComponent,
  challengeButtonComponent: ChallengeButtonComponent,
}) => {
  const classes = useStylesZone();

  const challenBtnClasses = classNames({
    [classes.challengeBtn]: true,
  });

  const getIconSize = () => {
    const factor = ((width - mapBaseline) / mapBaseline) * 100 * 0.8;
    if (factor <= 0) return iconBaseline;
    return (iconBaseline * factor) / 100 + iconBaseline;
  };

  const getAvatarSize = () => {
    const factor = ((width - mapBaseline) / mapBaseline) * 100 * 0.8;
    if (factor <= 0) return avatarBaseline;
    return (avatarBaseline * factor) / 100 + avatarBaseline;
  };

  const iconSize = getIconSize();
  const avatarSize = getAvatarSize();

  const positionMap = {
    0: {
      top: `calc(10% - ${iconSize / 2}px)`,
      right: `calc(23% - ${iconSize / 2}px)`,
      height: iconSize,
      width: iconSize,
    },
    1: {
      top: `calc(54% - ${iconSize / 2}px)`,
      right: `calc(24% - ${iconSize / 2}px)`,
      height: iconSize,
      width: iconSize,
    },
    2: {
      top: `calc(75% - ${iconSize / 2}px)`,
      right: `calc(43% - ${iconSize / 2}px)`,
      height: iconSize,
      width: iconSize,
    },
    3: {
      top: `calc(42% - ${iconSize / 2}px)`,
      right: `calc(64% - ${iconSize / 2}px)`,
      height: iconSize,
      width: iconSize,
    },
    4: {
      top: `calc(11% - ${iconSize / 2}px)`,
      right: `calc(45% - ${iconSize / 2}px)`,
      height: iconSize,
      width: iconSize,
    },
  };

  return (
    <div style={{ width: width }}>
      <div className={classes.root}>
        {challengeButton.map((c, i) => (
          <div
            key={i}
            id={i}
            className={challenBtnClasses}
            style={positionMap[i]}
          >
            {ChallengeButtonComponent && <ChallengeButtonComponent />}
          </div>
        ))}
        <div         
        style={{
          height: "avatarSize",
          width: avatarSize,
          position: "absolute",
          top: `calc(20% - ${avatarSize / 2}px)`,
          right: `calc(43% - ${avatarSize / 2}px)`,
        }}
      >
{AvatarInterfacePanel && <AvatarInterfacePanel/>}
        </div>
      </div>
    </div>
  );
};

export default Zone;
