import React from "react";
import Lottie from "react-lottie";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Clear";

import ProgressBar from "../assets/ProgressBar";

import animationData from "../assets/heart-animation";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "transparent",
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0.5),
  },
  Btn: {
    padding: "4px",
    "& svg": {
      height: "40px",
      width: "40px",
      color: "#c1c1c1",
    },
  },
}));

const TaskTopBar = (props) => {
  const { onCancel, progress, cancelDisable, taskIndex } = props;
  const classes = useStyles();
  const [isStopped, setIsStopped] = React.useState(true);

  React.useEffect(() => {
    if (taskIndex === 0) return
    setIsStopped(false);
  }, [taskIndex]);

  const lottieOptions = {
    loop: false,
    autoplay: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <AppBar color="default" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <IconButton
          className={classes.Btn}
          onClick={onCancel}
          disabled={cancelDisable}
        >
          <CancelIcon size="large" />
        </IconButton>
        <ProgressBar progress={progress} />
        <div className={classes.endAdornmentWrapper}>
          <Lottie
            options={lottieOptions}
            height={70}
            width={70}
            isStopped={isStopped}
            eventListeners={[
              {
                eventName: "complete",
                callback: () => {
                  setIsStopped(true);
                },
              },
            ]}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TaskTopBar;
