import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import ChallengeChecklist from "components/ChallengeChecklist";

export default function ChallengeChechlistView() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <ChallengeChecklist>
      </ChallengeChecklist>
    </Box>
  );
}

