import React from "react";
import Typography from "@material-ui/core/Typography";
import { Container, List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import backgroundContentImage from "./bg-send-love-back-alt.svg";
import backgroundContentImageSmall from "./bg-send-love-back-small.svg";

const useStyles = makeStyles((theme) => ({
  innerWrapperContent: {
    margin: "24px",
  },

  elementMarginS: {
    marginTop: theme.spacing(1),
  },
  listItem: {
    color: "rgba(0,0,0, 87)",
    "&:hover": {
      color: "rgba(214,61,7, 1)",
    },
    marginTop: "0px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "8px",
    },
  },
  personalReply: {
    fontFamily: "Kalam",
    fontWeight: 700,
  },
  innerWrapperBG: {
    backgroundImage: `url(${backgroundContentImageSmall})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    alignItems: "center",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${backgroundContentImage})`,
      width: "100%",
      height: "100%",
    },
  },
}));

const StageThree = (props) => {
  const { onAction } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.innerWrapperBG}>
      <Grid
        className={classes.innerWrapperContent}
        container
        direction="column"
      >
        <Grid container>
          <List className={classes.personalReply}>
            <ListItem
              className={classes.listItem}
              onClick={() => onAction("stageFour")}
            >
              Really helpful thanks :o)
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => onAction("stageFour")}
            >
              My heart is still smiling from your donation
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => onAction("stageFour")}
            >
              The banana bread was fabulous. You made my day with your donation.
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => onAction("stageFour")}
            >
              Thank you really appreciate your donation!
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StageThree;
