import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Box, Button } from "@material-ui/core";

import * as ROUTE from "routing/constants";

const BaseView = () => {
  const history = useHistory();
  return (
    <Container>
      <Box p={4} display="flex" flexDirection="column">
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.NOTIFICATION_CARD1)}
          >
            NotificationCard1
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.NOTIFICATION_CARD2)}
          >
            NotificationCard2
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.NOTIFICATION_CARD3)}
          >
            NotificationCard3
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.PROFILE_BUBBLES)}
          >
            Profile Bubbles
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.CHALLENGE_CHECKLIST)}
          >
            Challenge Checklist
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.ZONE)}
          >
            Zone
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.NOTIFICATION_CARD3_5)}
          >
            NotificationCard3_5
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.CLOUDISLAND)}
          >
            Big5 - Cloud Island Forrest Skin
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.CLOUDISLAND_FLOWERS)}
          >
            Big5 - Cloud Island Flowers Skin
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.CLOUDISLAND_MOUNTAINS)}
          >
            Big5 - Cloud Island Mountains Skin
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.BIG5)}
          >
            Big5 - Galaxy Skin
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.MAP)}
          >
            Map - Cloud Island Skin
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.MAP2)}
          >
            Map2 - Cloud Island Skin
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.AREA3_MINIISLAND2)}
          >
            Area3 - Mini Island 2 - Cloud Island Skin
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.ACHIEVEMENT)}
          >
            Achievement
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.ACHIEVEMENT_BADGE_ATTACHMENT)}
          >
            Achievement Bagde Attachment
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.AVATAR_INTERFACE_PANEL)}
          >
            Avatar Interface Panel
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.ANIMATION_TEST)}
          >
            Animation Test
          </Button>
        </Box>
        <Box my={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTE.ANIMATION_ROUTE_TRANSITION1)}
          >
            Route Transition 1
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default BaseView;
