import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import ProfileBubbles123 from "components/ProfileBubbles123";

export default function ProfileBubblesView() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <ProfileBubbles123 />
    </Box>
  );
}
