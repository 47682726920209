import React from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Container, Box, CssBaseline } from "@material-ui/core";
import EntryRoutes from "routing/EntryRoutes";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#5959C0",
      light: "#9C9CD9",
      variant: "#9C9CD9",
    },
    secondary: {
      main: "#C62A3A",
      light: "#ff828c",
    },
    background: {
      default: "#15151C",
      paper: "#1E1E24",
      primary: "#15151C",
      secondary: "#1E1E24",
      tertiary: "#27272D",
    },
    muted: {
      main: '#545458',
    },
    error: {
      main: '#b00020',
    },
    fill: {
      main: 'rgba(255, 255, 255, 0.15)',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.83)',
      secondary: 'rgba(255, 255, 255, 0.6)',
      tertiary: 'rgba(255, 255, 255, 0.3)',
      highlight: 'rgba(255, 255, 255, 1)',
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiChip: {
      root: {
        backgroundColor: 'rgba(255,255,255,0.12)',
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Container maxWidth={false} disableGutters>
          <EntryRoutes />
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;
