import React from "react";
import ChallengeState from "./state";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  wrapper: {
    position: "realtive",
    height: "100%",
    width: "100%",
  },
});

// const handleDone = (data) => {
//   console.log("DATA READY TO SERVER", data);
// };

const Challenge = (props) => {
  const classes = useStyles();
  const {
    skin,
    options,
    tasks,
    onTaskChange,
    onTasksDone,
    onScreenChange,
    onTasksCancel,
  } = props;
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <ChallengeState
          options={options}
          tasks={tasks}
          {...skin}
          onTasksDone={onTasksDone}
          onTaskChange={onTaskChange}
          onScreenChange={onScreenChange}
          onTasksCancel={onTasksCancel}
        />
      </div>
    </div>
  );
};

export default Challenge;
