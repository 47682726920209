import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { Paper, Box, Button, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import backgroundImage from "./upskill.svg";
import backgroundImageSmall from "./upskill-small.svg";
import Grid from "@material-ui/core/Grid";

import StageOne from "./StageOne";
import StageTwo from "./StageTwo";

const useStylesNoticationCard2 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    overflow: "hidden",
    margin: "auto",
    backgroundColor: "rgba(103,178,164,1)",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "center",
    backgroundPositionX: "center",
    maxWidth: 552,
  },
  leftWrapper: {
    flexWrap: "nowrap",
  },
  img: {
    backgroundImage: `url(${backgroundImageSmall})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    alignSelf: "flex-end",
    display: "block",
    width: "104px",
    height: "282px",
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${backgroundImage})`,
      width: "204px",
      height: "282px",
    },
  },

  outerWrapperContent: {
    alignContent: "center",
  },
}));

const GetStages = (props) => {
  const { stage, onAction } = props;
  if (stage === "stageOne") return <StageOne onAction={onAction} />;
  if (stage === "stageTwo") return <StageTwo onAction={onAction} />;
  return <StageOne />;
};

const NotificationCard2 = (props) => {
  const [stage, setStage] = React.useState("stageOne");
  const classes = useStylesNoticationCard2();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container className={classes.leftWrapper}>
          <Grid item xs={3} className={classes.img} />
          <Grid item xs={9} container className={classes.outerWrapperContent}>
            <GetStages
              onAction={(value) => {
                setStage(value);
              }}
              stage={stage}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default NotificationCard2;
