import React from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import { ButtonBase } from "@material-ui/core";
import AvatarMap from "components/AvatarMap";
import ProfileActionButtonPrompt from "components/ProfileActionButtonPrompt";
import ProfileActionButtonCompare from "components/ProfileActionButtonCompare";
import AchievementBadgeAttachment from "components/AchievementBadgeAttachment";

const useStylesAvatarInterfacePanel = makeStyles((theme) => {
  console.log(theme);
  return {
    root: {
      marginTop: "60px",
    },
    /*     outer: {
      position: "relative",
      padding: "28px",
      backgroundColor: "green",
    }, */
    inner: {
      position: "relative",
      width: "128px",
      // margin: "2px",
    },
    profileActionButtonPromptStyle: {
      position: "absolute",
      top: `calc(-2%)`,
      right: `calc(-2%)`,
    },
    profileActionButtonCompareStyle: {
      position: "absolute",
      top: `calc(71%)`,
      right: `calc(-2%)`,
    },
    achievementBadgeAttachmentStyle: {
      position: "absolute",
      top: `calc(0%)`,
      right: `calc(70%)`,
    },
  };
});

const AvatarInterfacePanel = (props) => {
  const classes = useStylesAvatarInterfacePanel();
  return (
    <div className={classes.root}>
      <div className={classes.outer}>
        <Grid container className={classes.inner}>
          <AvatarMap />
          <div className={classes.profileActionButtonPromptStyle}>
            <ButtonBase>
              <ProfileActionButtonPrompt />
            </ButtonBase>
          </div>
          <div className={classes.profileActionButtonCompareStyle}>
            <ButtonBase>
              <ProfileActionButtonCompare />
            </ButtonBase>
          </div>
          <div className={classes.achievementBadgeAttachmentStyle}>
            <ButtonBase>
              <AchievementBadgeAttachment />
            </ButtonBase>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default AvatarInterfacePanel;

/* 
<Grid container className={classes.avatarStyling}>
<AvatarMap></AvatarMap>
  </Grid> */
