import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Avatar } from "@material-ui/core";
import backgroundImage from "./Michael.png";

const useStyles = makeStyles((theme) => ({
  avatarStyling: {
    width: "100%",
    height: "100%",
  },
}));

const ChallengeAvatar = (props) => {
  const classes = useStyles();
  return <Avatar src={backgroundImage} className={classes.avatarStyling}></Avatar>;
};

export default ChallengeAvatar;
