import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import Area3MiniIsland2 from "components/Area3MiniIsland2";

export default function Area3MiniIsland2View() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Area3MiniIsland2 />
    </Box>
  );
}