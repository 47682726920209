import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { Paper, Box, Button, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import backgroundContentImage from "./bg-send-love-back-alt.svg";
import backgroundContentImageSmall from "./bg-send-love-back-small.svg";

const useStyles = makeStyles((theme) => ({
  innerWrapperContent: {
    margin: "24px",
  },

  elementMarginS: {
    marginTop: theme.spacing(1),
  },
  personalReply: {
    color: "rgba(214,61,77, 1)",
    fontFamily: "Kalam",
    fontWeight: 700,
  },
  personalSignature: {
    color: "rgba(0,0,0, 0.60)",
    /*     fontFamily: "Kalam", */
  },
  innerWrapperBG: {
    backgroundImage: `url(${backgroundContentImageSmall})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    alignItems: "center",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${backgroundContentImage})`,
      width: "100%",
      height: "100%",
    },
  },
}));

const StageOne = (props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.innerWrapperBG}>
      <Grid
        className={classes.innerWrapperContent}
        container
        direction="column"
      >
        <div>
          <Typography variant="h6" className={classes.personalReply}>
            Thank you really appreciate your donation!
          </Typography>
        </div>
        <div className={classes.elementMarginS}>
          <Typography
            className={classes.personalSignature}
            variant="caption"
            display="inline"
          >
            - Anonymous talent
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default StageOne;
