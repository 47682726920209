import React from "react";
import { Paper  } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import backgroundImage from "./talent-donation-love.svg";
import backgroundImageSmall from "./talent-donation-love-small.svg";
import Grid from "@material-ui/core/Grid";

import StageOne from "./StageOne";

const useStylesNoticationCard3_5 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    overflow: "hidden",
    margin: "auto",
    backgroundColor: "rgba(229,185,0,1)",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "center",
    backgroundPositionX: "center",
    maxWidth: 552,
  },
  leftWrapper: {
    flexWrap: "nowrap",
  },
  img: {
    backgroundImage: `url(${backgroundImageSmall})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    alignSelf: "flex-end",
    display: "block",
    width: "104px",
    height: "282px",
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${backgroundImage})`,
      width: "204px",
      height: "282px",
    },
  },
  outerWrapperContent: {
    alignContent: "center",
  },
}));

const GetStages = (props) => {
  return <StageOne />;
};

const NotificationCard3_5 = (props) => {
  const [ setStage ] = React.useState("stageOne");
  const classes = useStylesNoticationCard3_5();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container className={classes.leftWrapper}>
          <Grid item xs={3} className={classes.img} />
          <Grid item xs={9} container className={classes.outerWrapperContent}>
            <GetStages />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default NotificationCard3_5;
