import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, List, ListItem, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "8px",
    paddingRight: "8px",
    marginBottom: "16px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "32px",
      paddingLeft: "16px",
      paddingRight: "16px",
      textAlign: "center",
    },
  },
}));

const Question = (props) => {
  const { question } = props;
  const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="xs">
      <Typography variant="h6">{question}</Typography>
    </Container>
  );
};

export default Question;
