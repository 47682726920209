import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, List, ListItem, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "56px",
    paddingRight: "56px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "108px",
      paddingRight: "96px",
    },
  },
  listItem: {
    color: "rgba(255,255,255, 0.60)",
    '&:active': {
        color: "rgba(255,218,61, 1)"
      },
  },
}));

const Options = (props) => {
  const { options, task, done, onClick } = props;
  const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="xs" >
    <List>
      {options[task.keyed].map((option) => (
        <ListItem
          className={classes.listItem}
          disabled={done}
          button
          onClick={() => onClick(option)}
          key={option.id}
        >
          {option.text}
        </ListItem>
      ))}
    </List>
    </Container>
  );
};

export default Options;
