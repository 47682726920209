import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import Grid from "@material-ui/core/Grid";

// Zone CloudIsland
import Zone from "components/Zone";

// Profile Bubbles
import ProfileBubbles123 from "components/ProfileBubbles123";

// Challenge Checklist
import ChallengeChecklist from "components/ChallengeChecklist";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "fixed",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(80,80,173,1)",
  },
  topWrapper: {
    height: "20vh",
/*     backgroundColor: "red", */
  },
  zoneWrapper: {
    height: "80vh",
    alignItems: "center",
    justifyContent: "center",
/*     backgroundColor: "yellow", */
  },
  barWrapper: {
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    flexWrap: "nowrap",
    height: "20vh",
    margin: "16px",
    /*     position: "fixed", */
  },
}));

const Map2 = (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid className={classes.root}>
      <Grid className={classes.topWrapper} />
      <Grid container className={classes.zoneWrapper}>
        <Zone />
      </Grid>
      <Grid container className={classes.barWrapper}>
        <ChallengeChecklist />
        <ProfileBubbles123 />
      </Grid>
    </Grid>
  );
};

export default Map2;
