import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Typography,
  Grid,
  ButtonBase,
  Avatar,
} from "@material-ui/core";

import plus1 from "../assets/p1.png";
import plus2 from "../assets/p2.png";
import plus3 from "../assets/p3.png";
import plus4 from "../assets/p4.png";
import plus5 from "../assets/p5.png";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "110px",
    minWidth: "50%",
    margin: "0 auto",
    padding: "12px",
    position: "relative",
  },
  btn: {
    borderRadius: "50%",
    padding: "12px",
    borderStyle: "solid",
    borderColor: "rgba(255,255,255,0.1)",
    borderWidth: "1px",
    backgroundColor: "rgba(255,255,255,0.1)",
  },
  icon: {
    width: "80px",
    height: "80px",
  },
  info: {
    position: "absolute",
    bottom: "-8px",
    left: 0,
    right: 0,
    backgroundColor: "rgba(255,255,255,0.25)",
    borderRadius: "8px",
    marginLeft: "6px",
    marginRight: "6px",
  },
}));

// This will limit the skin to only handle plus/minus 5 pcs options
const iconHandler = {
  plus1: plus1,
  plus2: plus2,
  plus3: plus3,
  plus4: plus4,
  plus5: plus5,
  minus1: plus5,
  minus2: plus4,
  minus3: plus3,
  minus4: plus2,
  minus5: plus1,
};

const Options = (props) => {
  const classes = useStyles();
  const { options, task, done, onClick, info } = props;

  return (
    <Container maxWidth="sm">
      <Grid container>
        {options[task.keyed].map((option) => {
          return (
            <Grid item key={option.id} className={classes.item}>
              <ButtonBase
                disabled={done}
                onClick={() => onClick(option)}
                className={classes.btn}
              >
                <Avatar
                  variant="square"
                  src={iconHandler[task.keyed + option.score]}
                  className={classes.icon}
                />
              </ButtonBase>
              {info && (
                <Typography
                  variant="caption"
                  className={classes.info}
                  align="center"
                >
                  {option.text}
                </Typography>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Options;
