import React from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import ProfileBubble1 from "components/ProfileBubble1";
import ProfileBubble2 from "components/ProfileBubble2";
import ProfileBubble3 from "components/ProfileBubble3";
import { Box } from "@material-ui/core";

const useStylesProfileBubbles123 = makeStyles((theme) => {
  console.log(theme);
  return {
    profileBubblesWrapper: {
      /*      display: "inline-flex", */
      flexWrap: "nowrap",
      justifyContent: "center",
      allignItems: "center",
      background: "linear-gradient(to right, rgba(67,67,145,0) 0%, rgba(67,67,145,0.8) 50%, rgba(67,67,145,0.0) 100%)",
      borderRadius: "16px",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "48px",
      paddingRight: "48px",
      margin: "4px",
      height: "fit-content",
      width: "fit-content",
    },
    profileBubbleStyle: {
      height: "100%",
      width: "60px",
      marginLeft: "8px",
      marginRight: "8px",
    },
  };
});

const ProfileBubbles123 = () => {
  const classes = useStylesProfileBubbles123();
  return (
    <Grid
      container
      className={classes.profileBubblesWrapper }
    >
      <Grid className={classes.profileBubbleStyle}>
        <ProfileBubble1 />
      </Grid>
      <Grid className={classes.profileBubbleStyle}>
        <ProfileBubble2 />
      </Grid>
      <Grid className={classes.profileBubbleStyle}>
        <ProfileBubble3 />
      </Grid>
    </Grid>
  );
};

export default ProfileBubbles123;
