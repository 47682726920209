import React from "react";
/* import PropTypes from "prop-types"; */
/* import Typography from "@material-ui/core/Typography"; */
import { makeStyles } from "@material-ui/styles";
import UncontrolledLottie from "./UncontrolledLottie";
import Grid from "@material-ui/core/Grid";

const useStylesStageTwo = makeStyles((theme) => ({
  avatar: {
    // width: theme.spacing(5),
    // height: theme.spacing(5),
    // borderRadius: "12px",
    marginRight: theme.spacing(2),
  },
}));

const StageTwo = () => {
  const classes = useStylesStageTwo();
  return (
    <Grid container direction="column" className="lotties">
      <UncontrolledLottie />
    </Grid>
  );
};

export default StageTwo;
