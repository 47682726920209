import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, List, ListItem, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "100%",
    backgroundColor: "purple",
    color: "yellow",
  },
}));

const Init = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth={false} >
      <Typography variant="h5" color="inherit">
        Get ready
      </Typography>
    </Container>
  );
};

export default Init;
