import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Box } from "@material-ui/core";
import { MinimalTopBar } from "components/MinimalTopBar";
import Zone from "components/Zone";
import Avatar from "components/CloudIslandSkin/assets/Avatar";
import ChallengeBtn from "components/ChallengeButton";

export default function ZoneView() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Zone
        avatarComponent={Avatar}
        challengeButtonComponent={ChallengeBtn}
      />
    </Box>
  );
}

