import React from "react";
import { makeStyles } from "@material-ui/styles";
import iconImage from "./achievement-badge-attachment-explorer-journeyman.svg";

const useStylesAchievementBadgeAttachment = makeStyles((theme) => {
  console.log(theme);
  return {
  };
});

const AchievementBadgeAttachment = () => {
  const classes = useStylesAchievementBadgeAttachment();
  return (
    <div>
    <img src={iconImage} />
    </div>
  );
};

export default AchievementBadgeAttachment;