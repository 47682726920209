import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "relative",
    paddingLeft: "12px",
    paddingRight: "20px",
  },
  customizeLinearProgress: {
    minHeight: "20px",
    borderRadius: "8px",
  },
  colorPrimary: {
    backgroundColor: "rgba(140,122,104, 1)",
  },
  barColorPrimary: {
    backgroundColor: "rgba(255,218,61, 1)",
  },
});

const ProgressBar = (props) => {
  const classes = useStyles();
  const { progress } = props;
  return (
    <div className={classes.root}>
      <LinearProgress
        className={classes.customizeLinearProgress}
        classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary,
        }}
        variant="determinate"
        value={progress}
      />
    </div>
  );
};

export default ProgressBar;
