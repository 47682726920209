export const NOTIFICATION_CARD1 = "/notificationcard1";
export const NOTIFICATION_CARD2 = "/notificationcard2";
export const NOTIFICATION_CARD3 = "/notificationcard3";
export const PROFILE_BUBBLES = "/ProfileBubbles"; 
export const CHALLENGE_CHECKLIST = "/ChallengeChecklist"; 
export const ZONE = "/Zone";
export const NOTIFICATION_CARD3_5 = "/notificationcard3_5";
export const CLOUDISLAND = "/Cloudisland";
export const CLOUDISLAND_FLOWERS = "/CloudislandFlowers";
export const CLOUDISLAND_MOUNTAINS = "/CloudislandMountains";
export const BIG5 = "/big5";
export const MAP = "/Map";
export const MAP2 = "/Map2";
export const AREA3_MINIISLAND2 = "/Area3MiniIsland2";
export const ACHIEVEMENT = "/Achievement";
export const ACHIEVEMENT_BADGE_ATTACHMENT = "/AchievementBadgeAttachment";
export const AVATAR_INTERFACE_PANEL = "/AvatarInterfacePanel";
export const ANIMATION_TEST = "/AnimationTest";
export const ANIMATION_ROUTE_TRANSITION1 = "/AnimationRouteTransition1";