import React from "react";
import PropTypes from "prop-types";

const State = (props) => {
  const {
    Task,
    Done,
    Init,
    settings = {},
    tasks,
    options,
    onTasksDone = () => {},
    onTaskChange = () => {},
    onScreenChange = () => {},
    onTasksCancel = () => {},
  } = props;
  const [screen, setScreen] = React.useState("init");
  const [loading, setLoading] = React.useState(false);
  const [answers, setAnswers] = React.useState([]);
  const [timeStart, setTimeStart] = React.useState();
  const [timeTaskSubmit, setTimeTaskSubmit] = React.useState();

  React.useEffect(() => {
    // TODO: Use loading state to change screen
    let initTimer = setTimeout(() => {
      setScreen("task");
      setTimeStart(new Date().getTime());
    }, settings.init?.minWait || 3000);
    return () => {
      clearTimeout(initTimer);
    };
  }, [settings.init]);

  React.useEffect(() => {
    onScreenChange(screen);
  }, [onScreenChange, screen]);

  const handleOnChange = (answer, currentProgress) => {
    const t = new Date().getTime();
    const timing = {
      start: timeStart,
      prev: timeTaskSubmit || timeStart,
      current: t,
    };
    const progress = {
      total: tasks.length,
      current: currentProgress + 1,
    };
    setAnswers([...answers, { answer, timing, progress }]);
    onTaskChange({
      answer,
      timing,
      progress,
    });
    setTimeTaskSubmit(t);
  };

  const onDoneTasks = () => {
    onTasksDone({
      answers,
      timing: {
        start: timeStart,
        finish: new Date().getTime(),
      },
    });
    let lastTimer = setTimeout(() => {
      setScreen("done");
    }, settings.task.waitLast || 3000);
    return () => {
      clearTimeout(lastTimer);
    };
  };

  const handleOnCancelTasks = () => {
    onTasksCancel({
      answers,
      progress: {
        total: tasks.length,
        cancelled: answers.length + 1,
      },
      timing: {
        start: timeStart,
        cancelled: new Date().getTime(),
      },
    });
  };

  const showScreen = {
    init: <Init setting={settings} />,
    task: (
      <Task
        onDone={onDoneTasks}
        tasks={tasks}
        options={options}
        onChange={handleOnChange}
        settings={settings}
        onCancel={handleOnCancelTasks}
      />
    ),
    done: <Done settings={settings} />,
  };

  return showScreen[screen];
};

export default State;
