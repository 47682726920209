import React from "react";
import { makeStyles } from "@material-ui/styles";
import iconImage from "./profile-action-icon-new-insight.svg";

const useStylesProfileActionButtonPrompt = makeStyles((theme) => {
  console.log(theme);
  return {
  };
});

const ProfileActionButtonPrompt = () => {
  const classes = useStylesProfileActionButtonPrompt();
  return (
    <div>
    <img src={iconImage} />
    </div>
  );
};

export default ProfileActionButtonPrompt;