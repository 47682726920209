import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStylesModal = makeStyles((theme) => {
  console.log(theme);
  return {
  };
});

const Modal = ({ isToggled, setToggle, children }) => {
  const classes = useStylesModal();
  return (
    <AnimatePresence>
      {isToggled && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            ease: "easeOut",
            delay: 1.4,
            stiffness: 260,
            damping: 20,
          }}
          style={{
            position: "fixed",
            top: "600px",
            left: "50%",
            transform: "translate3d(-50%, 0,0)",
          }}
        >
          <motion.div initial={{ y: 50 }} animate={{ y: 1 }} exit={{ y: 30 }}>
            <button onClick={() => setToggle(false)}>Close</button>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
