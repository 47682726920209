import React from "react";
import { Paper, Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import iconImage from "./new-insight.svg";

const useStylesAchievement = makeStyles((theme) => {
  console.log(theme);
  return {
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      backgroundColor: "rgba(229,185,0, 0.9)",
      width: "100%",
      height: "212px",
      borderStyle: "solid",
      borderWidth: "4px",
      borderColor: "rgba(255,230,122, 1)",
    },
    topWrapper: {
      height: "20%",
      alignItems: "center",
      justifyContent: "center",
    },
    middleWrapper: {
      width: "100%",
      height: "60%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-evenly",
      background:
        "linear-gradient(to right, rgba(198,147,0, 0) 0%, rgba(198,147,0, 1) 50%, rgba(198,147,0, 0) 100%)",
    },
    bottomWrapper: {
      height: "20%",
      alignItems: "center",
      justifyContent: "center",
    },
    imageIconBackground: {
      alignItems: "center",
      justifyContent: "center",
      height: "64px",
      width: "64px",
      borderRadius: "8px",
      backgroundColor: "rgba(161,107,22, 1)",
    },
    categoryText: {
      color: "rgba(0,0,0, 0.87)",
      fontWeight: "500",
    },
    titleText: {
      color: "rgba(255,255,255, 0.87)", // Add primary color
      fontWeight: "bold",
    },
    captionText: {
      color: "rgba(0,0,0, 0.60)",
    },
  };
});

const Achievement = () => {
  const classes = useStylesAchievement();
  return (
    <Paper className={classes.paper}>
      <Grid container className={classes.topWrapper}>
        <Typography variant="caption" className={classes.categoryText}>
          New personality assessment unlocked
        </Typography>
      </Grid>
      <Grid container className={classes.middleWrapper}>
        <Grid container className={classes.imageIconBackground}>
          <img src={iconImage} />
        </Grid>
        <Box>
          <Typography variant="h5" className={classes.titleText}>
            Level 1
          </Typography>
        </Box>
      </Grid>
      <Grid container className={classes.bottomWrapper}>
        <Typography
          variant="caption"
          className={classes.captionText}
        ></Typography>
      </Grid>
    </Paper>
  );
};

export default Achievement;
