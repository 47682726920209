import React from "react";
import classNames from "classnames";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import ToolBar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import HideOnScroll from "components/HideOnScroll";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.secondary,
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "transparent",
    },
  },
  iconButton: {
    padding: "8px",
    [theme.breakpoints.up("sm")]: {
      padding: "12px",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  backIcon: {
    "& path": {
      transform: "translate(4px, 0px)",
    },
  },
  title: {
    marginLeft: theme.spacing(1),
    flexGrow: 1,
  },
  transparent: {
    backgroundColor: "transparent",
  },
}));

export const MinimalTopBar = (props) => {
  const classes = useStyles();
  const {
    onGoBack,
    pageTitle,
    rightComponent: RightComponent,
    leftAlternateComponent: LeftAlternateComponent,
    leftAlternateShow,
    hideOnScroll = false,
    fixed = false,
    disableGoBack,
    transparent = false,
  } = props;

  const classesAppBar = classNames({
    [classes.appBar]: true,
    [classes.transparent]: transparent,
  });
  return (
    <HideOnScroll active={!!hideOnScroll}>
      <AppBar
        position={fixed ? "fixed" : "relative"}
        color="default"
        elevation={0}
        className={classNames(classesAppBar)}
      >
        <ToolBar>
          {leftAlternateShow && LeftAlternateComponent ? (
            LeftAlternateComponent
          ) : (
            <IconButton
              disabled={disableGoBack}
              onClick={onGoBack}
              className={classes.iconButton}
            >
              <BackIcon className={classes.backIcon} />
            </IconButton>
          )}
          <Typography className={classes.title} variant="h6">
            {pageTitle}
          </Typography>
          {RightComponent && <div>{RightComponent}</div>}
        </ToolBar>
      </AppBar>
    </HideOnScroll>
  );
};

const Minimal = (props) => {
  const { children } = props;
  return (
    <>
      <CssBaseline />
      <Container maxWidth="lg" disableGutters>
        {children}
      </Container>
    </>
  );
};

export default Minimal;
