import React from "react";

import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import MuteIcon from "@material-ui/icons/MusicOff";
import UnMuteIcon from "@material-ui/icons/MusicNote";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "transparent",
    top: "auto",
    bottom: 0,
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(0.5),
  },
  Btn: {
    padding: "4px",
    "& svg": {
      height: "40px",
      width: "40px",
      color: "rgba(255,255,255,0.25)",
    },
  },
}));

const TaskBottomBar = (props) => {
  const { onMute, onInfo, muted = false } = props;
  const classes = useStyles();

  return (
    <AppBar color="default" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <IconButton className={classes.Btn} onClick={onMute}>
          {muted ? <UnMuteIcon size="large" /> : <MuteIcon size="large" />}
        </IconButton>
        <IconButton className={classes.Btn} onClick={onInfo}>
          <InfoIcon size="large" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default TaskBottomBar;
