import React from "react";
import { makeStyles } from "@material-ui/styles";
import ChallengeChecklistIcon from "components/ChallengeChecklistIcon";
import Grid from "@material-ui/core/Grid";

const useStylesChallengeChecklist = makeStyles((theme) => {
  console.log(theme);
  return {
    checklistWrapper: {
      display: "flex",
      justifyContent: "center",
      background: "linear-gradient(to right, rgba(67,67,145,0) 0%, rgba(67,67,145,0.8) 50%, rgba(67,67,145,0.0) 100%)",
      borderRadius: "16px",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingLeft: "32px",
      paddingRight: "32px",
      margin: "4px",
      height: "fit-content",
      width: "fit-content",
    },
    checklistIconStyle: {
      marginLeft: "8px",
      marginRight: "8px",
      height: "100%",
      width: "32px",
    },
  };
});

const ChallengeChecklist = () => {
  const classes = useStylesChallengeChecklist();
  return (
    <Grid className={classes.checklistWrapper}>
      <Grid className={classes.checklistIconStyle}>
        <ChallengeChecklistIcon />
      </Grid>
      <Grid className={classes.checklistIconStyle}>
        <ChallengeChecklistIcon />
      </Grid>
      <Grid className={classes.checklistIconStyle}>
        <ChallengeChecklistIcon />
      </Grid>
    </Grid>
  );
};

export default ChallengeChecklist;
