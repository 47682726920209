import React from "react";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import { MinimalTopBar } from "components/MinimalTopBar";
import Challenge from "components/Challenge";

/**
 * SKIN & TEST DATA
 */
import * as skin from "components/CloudIslandSkin";
import * as big5 from "data/big5";

const debug = process.env.REACT_APP_DEBUG || false;

export default function CloudIslandView() {
  const history = useHistory();
  const [screen, setScreen] = React.useState("");

  const handleGoBack = () => {
    history.goBack();
  };

  const handleOnTasksDone = (data) => {
    debug && console.log("HANDLE_ON_TASKS_DONE", { data });
  };

  const handleTaskChange = (data) => {
    debug && console.log("HANDLE_ON_TASK_CHANGE", { data });
  };

  const handleOnTasksCancel = (data) => {
    debug && console.log("HANDLE_ON_TASKS_CANCEL", { data });
    history.goBack();
  };

  return (
    <Container maxWidth={false} disableGutters>
      {screen === "done" && (
        <MinimalTopBar onGoBack={handleGoBack} static transparent />
      )}
      <Challenge
        skin={skin}
        tasks={big5.questions}
        options={big5.options}
        onScreenChange={setScreen}
        onTaskChange={handleTaskChange}
        onTasksDone={handleOnTasksDone}
        onTasksCancel={handleOnTasksCancel}
      />
    </Container>
  );
}