import React from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import backgroundImage from "../cloud-island-bg.svg";
import backgroundImageSmall from "../cloud-island-bg-small.svg";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    height: "100%",
    width: "100%",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(100,230,173, 1)",
/*     [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${backgroundImage})`,
    }, */
  },
}));

export const Skin3 = (props) => {
  const classes = useStyles();

  const { children } = props;
  return (
    <Grid container className={classes.wrapper}>
      {children}
    </Grid>
  );
};
