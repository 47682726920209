import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Box } from "@material-ui/core";
import { MinimalTopBar } from "components/MinimalTopBar";
import Map2 from "components/Map2";

export default function Map2View() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Container maxWidth={false} disableGutters>
      <MinimalTopBar onGoBack={handleGoBack} static transparent />
      <Map2 />
    </Container>
  );
}
