import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import BaseView from "views/BaseView";

import * as ROUTE from "routing/constants";

// VIEWS
import NotificationCard1View from "views/NotificationCard1View";
import NotificationCard2View from "views/NotificationCard2View";
import NotificationCard3View from "views/NotificationCard3View";
import ProfileBubblesView from "views/ProfileBubblesView";
import ChallengeChecklistView from "views/ChallengeChecklistView";
import ZoneView from "views/ZoneView";
import NotificationCard3_5View from "views/NotificationCard3_5View";
import CloudIslandView from "views/CloudIslandView";
import CloudIslandFlowersView from "views/CloudIslandFlowersView";
import CloudIslandMountainsView from "views/CloudIslandMountainsView";
import Big5View from "views/Big5";
import MapView from "views/MapView";
import Map2View from "views/Map2View";
import Area3MiniIsland2View from "views/Area3MiniIsland2View";
import AchievementView from "views/AchievementView";
import AchievementBadgeAttachmentView from "views/AchievementBadgeAttachmentView";
import AvatarInterfacePanelView from "views/AvatarInterfacePanelView";
import AnimationTestView from "views/AnimationTestView";
import AnimationRouteTransition1View from "views/AnimationRouteTransition1View";

const RoutesEntry = () => {
  return (
    <Router>
      <Switch>
        <Route path="/404">
          <div style={{ color: "blue" }}>NOT FOUND</div>
        </Route>
        <Route exact path={ROUTE.NOTIFICATION_CARD1}>
          <NotificationCard1View />
        </Route>
        <Route exact path={ROUTE.NOTIFICATION_CARD2}>
          <NotificationCard2View />
        </Route>
        <Route exact path={ROUTE.NOTIFICATION_CARD3}>
          <NotificationCard3View />
        </Route>
        <Route exact path={ROUTE.PROFILE_BUBBLES}>
          <ProfileBubblesView />
        </Route>
        <Route exact path={ROUTE.CHALLENGE_CHECKLIST}>
          <ChallengeChecklistView />
        </Route>
        <Route exact path={ROUTE.ZONE}>
          <ZoneView />
        </Route>
        <Route exact path={ROUTE.NOTIFICATION_CARD3_5}>
          <NotificationCard3_5View />
        </Route>
        <Route exact path={ROUTE.CLOUDISLAND}>
          <CloudIslandView />
        </Route>
        <Route exact path={ROUTE.CLOUDISLAND_FLOWERS}>
          <CloudIslandFlowersView />
        </Route>
        <Route exact path={ROUTE.CLOUDISLAND_MOUNTAINS}>
          <CloudIslandMountainsView />
        </Route>
        <Route exact path={ROUTE.BIG5}>
          <Big5View />
        </Route>
        <Route exact path={ROUTE.MAP}>
          <MapView />
        </Route>
        <Route exact path={ROUTE.MAP2}>
          <Map2View />
        </Route>
        <Route exact path={ROUTE.AREA3_MINIISLAND2}>
          <Area3MiniIsland2View />
        </Route>
        <Route exact path={ROUTE.ACHIEVEMENT}>
          <AchievementView />
        </Route>
        <Route exact path={ROUTE.ACHIEVEMENT_BADGE_ATTACHMENT}>
          <AchievementBadgeAttachmentView />
        </Route>
        <Route exact path={ROUTE.AVATAR_INTERFACE_PANEL}>
          <AvatarInterfacePanelView />
        </Route>
        <Route exact path={ROUTE.ANIMATION_TEST}>
          <AnimationTestView />
        </Route>
        <Route exact path={ROUTE.ANIMATION_ROUTE_TRANSITION1}>
        <AnimationRouteTransition1View />
      </Route>
        <Route exact path="/">
          <BaseView />
        </Route>
        {/* <Redirect to="/404" /> */}
      </Switch>
    </Router>
  );
};

export default RoutesEntry;
